.sliderBase {
	width: 100%;
	position: relative;
}

.slider {
	display: flex;
	overflow-x: auto;
	scrollbar-width: none; /* Firefox 64 */
	-ms-overflow-style: none; /* Internet Explorer 11 */
}

.slider::-webkit-scrollbar {
	/** WebKit */
	display: none;
}

.slider > * {
	flex: 0 0 auto;
}

.sliding > * {
	pointer-events: none;
}