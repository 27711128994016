.scrolling-carousel {
    height: 243px;
}

.scrolling-carousel-playlists {
    height: 450px;
    width: 100%;

    @media screen and (min-width: 768px) {
        margin-right: 75px;
    }
}
