.course-modal-react-markdown h1 {
  font-family: Poppins;
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;
  margin-bottom: 14px;
}

.course-modal-react-markdown p {
  line-height: 28px;
  margin-bottom: 18px;
}

.course-modal-react-markdown p:last-child {
  margin-bottom: unset;
}

.course-youtube-container {
  position: absolute;
  width: 100%;
  height: 150%;
  overflow: hidden;
}

.course-youtube-container iframe, .vimeo-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 30px 30px 0px 0px;
  bottom: 16%;
}

.trailer-youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.trailer-youtube-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px 30px 0px 0px;
}

.course-youtube-rounded iframe, .vimeo-video-rounded iframe {
  border-radius: 30px;
}
