.favorite-icon {
  svg {
    cursor: pointer;
    overflow: visible;
    width: 60px;
    .heart {
      transform-origin: center;
      animation: animateHeartOut 0.3s linear forwards;
      stroke: #313e4c;
      stroke-width: 2px;
      stroke-linejoin: round;
    }

    .heart__modal {
      transform-origin: center;
      animation: animateHeartOut 0.3s linear forwards;
      stroke: #fff;
      stroke-width: 2px;
      stroke-linejoin: round;
    }

    .main-circ {
      transform-origin: 29.5px 29.5px;
    }
  }

  @media screen and (max-width: 992px) {
    svg {
      width: 34px;
    }
  }

  .checkbox {
    display: none;
  }

  .checkbox:checked + label svg {
    .heart {
      transform: scale(0.2);
      fill: #009028;
      stroke-opacity: 0;
      animation: animateHeart 0.3s linear forwards 0.25s;
    }

    .heart__modal {
      transform: scale(0.2);
      fill: #009028;
      stroke-opacity: 0;
      animation: animateHeart 0.3s linear forwards 0.25s;
    }
    
    .main-circ {
      transition: all 2s;
      animation: animateCircle 0.3s linear forwards;
      opacity: 1;
    }
    .grp1 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(0, -30px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(10px, -50px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp2 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(60px, -15px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp3 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(60px, 10px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp4 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(30px, 15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(40px, 50px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp5 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(-10px, 20px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(-60px, 30px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp6 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(-30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(-60px, -5px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp7 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      .oval1 {
        transform: scale(0) translate(-30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      .oval2 {
        transform: scale(0) translate(-55px, -30px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    .grp2 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    .grp3 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    .grp4 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    .grp5 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    .grp6 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    .grp7 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
  }

  @keyframes animateCircle {
    40% {
      transform: scale(10);
      opacity: 1;
      fill: #3aa959;
    }
    55% {
      transform: scale(11);
      opacity: 1;
      fill: #61bf7b;
    }
    65% {
      transform: scale(12);
      opacity: 1;
      fill: #73e191;
    }
    75% {
      transform: scale(13);
      opacity: 1;
      fill: transparent;
      stroke: #73e191;
      stroke-width: 0.5;
    }
    85% {
      transform: scale(17);
      opacity: 1;
      fill: transparent;
      stroke: #73e191;
      stroke-width: 0.2;
    }
    95% {
      transform: scale(18);
      opacity: 1;
      fill: transparent;
      stroke: #73e191;
      stroke-width: 0.1;
    }
    100% {
      transform: scale(19);
      opacity: 1;
      fill: transparent;
      stroke: #73e191;
      stroke-width: 0;
    }
  }

  @keyframes animateHeart {
    0% {
      transform: scale(0.2);
    }
    40% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes animateHeartOut {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
}
