.card-youtube-container {
  position: absolute;
  width: 100%;
  height: 150%;
  overflow: hidden;
}

.card-youtube-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;

  left: 0;
  border-radius: 30px 30px 0px 0px;
  bottom: 16%;
}

.card-youtube-rounded iframe {
  border-radius: 30px;
}

.card-vimeo-video iframe {
  border-radius: 30px 30px 0px 0px;
}
